import { createStore } from "vuex";

export default createStore({
  state: {
    serialNumber: null,
    lockNumber: 1,
    openId:'',
    outOrderNo:'T1149988308760137728'
  },
  mutations: {
    setSerialNumber(state, serialNumber) {
      state.serialNumber = serialNumber;
    },
    setLockNumber(state, lockNumber) {
      state.lockNumber = lockNumber;
    },
    setopenId(state, openId) {
      state.openId = openId;
    },
    setoutOrderNo(state, outOrderNo) {
      state.outOrderNo = outOrderNo;
    },
  },
  actions: {},
  modules: {},
});
