<template>
  <div>
    <van-nav-bar title="订单列表" left-text="" left-arrow :fixed="true" :placeholder="true" @click-left="$router.go(-1)" />
    <van-tabs v-model="active" @click-tab="onClickTab" type="card">
      <van-tab title="今日订单"></van-tab>
      <van-tab title="历史订单"></van-tab>
    </van-tabs>

    <div v-if="istoday === 1">
      <OrderListItem v-for="item in todayorders" :key="item.order.id" :item="item"></OrderListItem>
      <div v-if="todayorders.length === 0">
        <span style="margin-top:24x ;">没有订单</span>
      </div>
    </div>
    <div v-if="istoday === 0">
      <OrderListItem v-for="item in historyorders" :key="item.order.id" :item="item"></OrderListItem>
      <div v-if="historyorders.length === 0">
        <span style="margin-top:24x ;">没有订单</span>
      </div>
    </div>
  </div>
  <Loading v-show="fullscreenLoading" title="Loading..." />
</template>

<script>
import OrderListItem from '@/components/OrderListItem.vue'
import axios from "../axios";
import { mapMutations } from "vuex";
import { showDialog } from "vant";
import Loading from '@/components/Loading.vue'

export default {
  components: {
    OrderListItem,
    Loading
  },
  computed: {
    openId() {
      return this.$store.state.openId;
    }
  },
  data() {
    return {
      active: 0,
      istoday: 1,
      todayorders: [],
      historyorders: [],
      fullscreenLoading:false
    };
  },
  methods: {
    ...mapMutations(["setopenId"]),
    async getHistoryOrders() {
      debugger
      const response = await axios.post("/yindian/getOrders", {
        PageSize: 100,
        PageIndex: 1,
        Codition: { Openid: this.openId },
        Sorts: { Dir: "desc", Field: "CreateTime" }
      });
      debugger
      console.log(response);
      if (response.status === 200 && response.data.status === 200) {
        this.historyorders = response.data.data.data;
        console.log(this.historyorders);
      } else {
        showDialog({
          message: "获取历史订单失败",
        });
      }
    },
    async getTodayOrders() {
      debugger
      const response = await axios.post("/yindian/getTodayOrders", {
        PageSize: 100,
        PageIndex: 1,
        Codition: { Openid: this.openId },
        Sorts: { Dir: "desc", Field: "CreateTime" }
      });
      debugger
      if (response.status === 200 && response.data.status === 200) {
        this.todayorders = response.data.data.data;

      } else {
        showDialog({
          message: "获取今日订单失败",
        });
      }
    },
    async onClickTab(title) {
      console.log(title);
      this.fullscreenLoading = true;
      if (title.title == "今日订单") {
        this.istoday = 1;
        await this.getTodayOrders();
      } else {
        this.istoday = 0;
        await this.getHistoryOrders();
      }
      this.fullscreenLoading = false;
    }
  },
  async created() {
    this.fullscreenLoading = true;
    await this.getTodayOrders();
    this.fullscreenLoading = false;
  }
};
</script>

<style scoped>
.order {
  background-color: #fafafa;
}

.van-tabs {
  position: sticky;
  top: 0;
}

.protocol-text {
  width: 100vw;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
}

.protocol-line {
  width: 100%;
  height: 18px;
}

.nav-back {
  width: 100vw;
  height: 50px;
}
</style>
