<template>
  <div>
    <van-nav-bar
      title="用户协议"
      left-text=""
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="backHome"
    />
    <div class="protocol-text">
      <div>尊敬的用户：</div>
      <div class="protocol-line"></div>
      <div>
        欢迎您使用我们的萤点网自动售货服务（以下简称为“本服务”）。在您使用本服务之前，请您仔细阅读并充分理解本用户协议（以下简称为“本协议”）中的各项条款，特别是免除或限制我们的免责条款、对您的权利限制条款、争议解决和法律适用条款。如您对协议有任何疑问，可向我们咨询。
      </div>
      <div class="protocol-line"></div>
      <div>
        当您开始使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成一致，成为我们的用户。如您不同意本协议任何内容，请勿使用本服务。
      </div>
      <div class="protocol-line"></div>
      <div>一、服务内容</div>
      <div>
        1.本服务是指我们通过设备向您提供自动售货服务，包括但不限于饮料、零食等商品（以下简称为“商品”）的购买。
      </div>
      <div class="protocol-line"></div>
      <div>
        2.您可以使用微信支付平台完成购买。您同意并授权我们在您使用本服务的过程中，收集、使用您的相关信息，以便更好地为您提供服务。我们会尊重并保护您的个人隐私，具体请参见我们的隐私政策。
      </div>
      <div class="protocol-line"></div>
      <div>
        3.本服务可能因设备故障、网络问题、供应商原因等原因出现故障或中断，我们将尽力维护和恢复，但无法保证服务的稳定、安全、无误差。
      </div>
      <div class="protocol-line"></div>
      <div>二、用户须知</div>
      <div>
        1.用户需确保在使用本服务时，遵守中国法律法规，不得利用本服务从事违法行为。如因违法行为导致的任何法律责任，由用户自行承担。
      </div>
      <div class="protocol-line"></div>
      <div>
        2.用户需确保购买的商品是其自愿购买的，商品的购买价格以实际支付时的显示价格为准。一旦购买成功，不予退款。
      </div>
      <div class="protocol-line"></div>
      <div>
        3.用户需妥善保管好购买的商品，我们对于用户因保管不善而造成的损失概不负责。4.用户在使用本服务时，应当遵守社会公序良俗，不得有侵犯他人权益的行为。如有侵犯他人权益的行为，用户需自行承担法律责任。
      </div>
      <div class="protocol-line"></div>
      <div>
        5.平台向用户提供的资金损失的先行赔付机制，如用户在使用当中出现审核质量问题出现纠纷，平台将会先行保障消费者权益，对供应商和运营商扣除保障金先行赔付先行保护消费者权益。
      </div>
      <div class="protocol-line"></div>
      <div>三、免责声明</div>
      <div>
        1.在法律允许的范围内，我们不对本服务做出任何明示或暗示的担保，包括但不限于适销性、特定用途适用性、无侵权等担保。
      </div>
      <div class="protocol-line"></div>
      <div>
        2.我们不对本服务中的商品的质量、安全、适用性等作出任何担保，商品购买后的使用风险由用户自行承担。如因商品质量问题导致的纠纷，用户应与商品生产商或供应商协商解决。
      </div>
      <div class="protocol-line"></div>
      <div>
        3.我们不对因设备故障、网络问题、供应商原因等非因我们原因导致的服务中断、延迟或其他不良现象承担责任。
      </div>
      <div class="protocol-line"></div>
      <div>
        4.用户因第三方如微信支付平台的原因而遭受的损失，我们不承担任何责任。
      </div>
      <div class="protocol-line"></div>
      <div>5.我们对本协议中的免责声明拥有最终解释权。</div>
      <div class="protocol-line"></div>
      <div>四、知识产权</div>
      <div>
        1.我们拥有本服务的著作权、商标权等知识产权。未经我们明确书面授权，用户不得以任何形式擅自使用、复制、修改、传播、公开发布或以其他方式侵犯我们的知识产权。
      </div>
      <div class="protocol-line"></div>
      <div>
        2.用户在使用本服务过程中产生的任何数据、信息等均归我们所有。我们有权对这些数据、信息进行合法的使用和处理。
      </div>
      <div class="protocol-line"></div>
      <div>五、争议解决与法律适用</div>
      <div>
        1.本协议的签订、履行、解释及争议解决均适用中华人民共和国法律。2.若您与我们之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至我们注册地有管辖权的人民法院诉讼解决。
      </div>
      <div class="protocol-line"></div>
      <div>六、其他</div>
      <div>
        1.如本协议的任何条款被认定为无效或不可执行，该等条款应视为可分割，不影响其他条款的效力。
      </div>
      <div class="protocol-line"></div>
      <div>
        2.我们保留在法律允许的范围内对本协议进行修改的权利。如本协议有任何变更，我们将在相关页面进行公告。您在本协议变更后继续使用本服务的，表示您已接受变更后的协议。3.本协议的最终解释权归我们所有。如您对本协议有任何疑问，请及时联系我们。
      </div>
      <div class="protocol-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    },
  },
  data() {
    return {};
  },
  methods: {
    backHome() {
      this.$router.push({
        path: "/",
        query: {
          serialNumber: this.serialNumber,
        },
      });
    },
  },
};
</script>

<style scoped>
.protocol-text {
  width: 100vw;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
}

.protocol-line {
  width: 100%;
  height: 18px;
}

.nav-back {
  width: 100vw;
  height: 50px;
}
</style>
