<template>
  <div>
    <van-nav-bar
      title="留言反馈"
      left-text=""
      left-arrow
      @click-left="backHome"
    />
    <van-form @submit="feedback">
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="6"
          autosize
          label="内容"
          type="textarea"
          placeholder="请输入留言"
        />
        <van-field
          v-model="tel"
          type="tel"
          label="联系方式"
          placeholder="请填联系方式"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import axios from "../axios";
import { showDialog } from "vant";
export default {
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    },
  },
  data() {
    return {
      tel: "",
      message: "",
    };
  },
  methods: {
    backHome() {
      this.$router.push({
        path: "/",
        query: {
          serialNumber: this.serialNumber,
        },
      });
    },
    async feedback() {
      if (this.message == "") {
        showDialog({
          message: "请填写内容",
        });
        return;
      }
      if (this.tel == "") {
        showDialog({
          message: "请填写联系方式",
        });
        return;
      }
      try {
        const response = await axios.post("/yindian/repair", {
          link: this.tel,
          msg: this.message,
          serialize: this.serialNumber,
        });
        if (response.status === 200) {
          showDialog({
            message: "提交成功",
          });
          this.tel = "";
          this.message = "";
        } else {
          showDialog({
            message: "提交失败",
          });
        }
      } catch (error) {
        showDialog({
          message: "提交失败",
        });
      }
    },
  },
};
</script>

<style scoped>
.door {
  border-top: solid #f7f1f1 1px;
  width: 100vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 999;
  align-items: center;
}
.open-door {
  width: 80vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 10px;
}

.open-door-text {
  margin-top: 10px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-door-title {
  height: 25px;
  font-size: 14px;
  font-weight: bolder;
}

.open-door-subtitle {
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  color: #a0a0a0;
}
.back_home {
  width: 40vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 50px;
}

.open-door-tips {
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-door-tips-icon {
  height: 16px;
  margin-right: 10px;
}
.open-door-tips-text {
  font-size: 10px;
  font-weight: 500;
}
</style>
