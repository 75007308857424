<template>
  <div>
    <div class="door">
      <div class="open-door-text">
        <div class="open-door-title">关门成功，请放心离开</div>
        <div class="open-door-subtitle">账单正在计算，稍后将为您推送～</div>
        <div class="back_home" @click="backHome">返回首页</div>
      </div>
    </div>
    <div class="open-door-tips">
      <img class="open-door-tips-icon" src="../static/wxpayflag.png" />
      <div class="open-door-tips-text">微信支付分 ｜ 550分及以上优享</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    },
  },
  methods: {
    backHome() {
      this.$router.push({
        path: "/",
        query: {
          serialNumber: this.serialNumber,
        },
      });
    },
  },
};
</script>

<style scoped>
.door {
  border-top: solid #f7f1f1 1px;
  width: 100vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 999;
  align-items: center;
}
.open-door {
  width: 80vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 10px;
}

.open-door-text {
  margin-top: 10px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-door-title {
  height: 25px;
  font-size: 14px;
  font-weight: bolder;
}

.open-door-subtitle {
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  color: #a0a0a0;
}
.back_home {
  width: 40vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 50px;
}

.open-door-tips {
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-door-tips-icon {
  height: 16px;
  margin-right: 10px;
}
.open-door-tips-text {
  font-size: 10px;
  font-weight: 500;
}
</style>
