<template>
  <div>
    <div class="door">
      <div class="open-door-text">
        <div class="open-door-title">{{ orderstatusText }}</div>
        <div v-if="isopened === false"><van-loading type="spinner" /></div>
        <div class="open-door-subtitle" v-if="isopened === true">{{ordersubText}}</div>
        <div class="back_home" @click="backHome">返回首页</div>
      </div>
    </div>
    <div class="open-door-tips">
      <img class="open-door-tips-icon" src="../static/wxpayflag.png" />
      <div class="open-door-tips-text">微信支付分 ｜ 550分及以上优享</div>
    </div>
  </div>
</template>

<script>
import axios from "../axios";
import { mapMutations } from "vuex";
import { showDialog } from "vant";
export default {
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    },
    outOrderNo() {
      return this.$store.state.outOrderNo;
    }
  },
  data() {
    return {
      orderinfo: {},
      orderstatusText: "开门中",
      ordersubText:"执行成功",
      isopened: false,
      intervalId: null
    }
  },
  methods: {
    backHome() {
      this.$router.push({
        path: "/",
        query: {
          serialNumber: this.serialNumber,
        },
      });
    },
    async getOrderInfo() {
      const response = await axios.post("/yindian/getOrderResult", {
        orderNo: this.outOrderNo
      });
      console.log(response);
      if (response.status === 200) {
        this.orderinfo = response.data.data;
        if (this.orderinfo) {
          var html = "无";
          if (this.orderinfo.sStatus == "0") {
            html = "开门中";
          }
          if (this.orderinfo.sStatus == "1") {
            html = "开门成功";
            this.isopened=true;
          }
          else if (this.orderinfo.sStatus == "2") {
            html = "开门失败";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "3") {
            html = "视频上传成功";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "4") {
            html = "视频上传失败";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "5") {
            html = "识别中";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "6") {
            html = "识别成功";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "7") {
            html = "识别失败";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "8") {
            html = "订单完成";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "9") {
            html = "订单取消";
            this.isopened=true;
          } else if (this.orderinfo.sStatus == "10") {
            html = "结算失败";
            this.isopened=true;
          }
          this.ordersubText=this.orderinfo.execMsg;
          this.orderstatusText= html;
        }
        console.log(this.orderinfo);
      } else {
        showDialog({
          message: "获取开门状态失败",
        });
      }
    },
  },
  async created() {
    await this.getOrderInfo();
    this.intervalId = setInterval(this.getOrderInfo, 1000); // 每1秒请求一次
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // 清除定时器
    }
  },
};
</script>

<style scoped>
.door {
  border-top: solid #f7f1f1 1px;
  width: 100vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 999;
  align-items: center;
}

.open-door {
  width: 80vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 10px;
}

.open-door-text {
  margin-top: 20px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-door-title {
  height: 25px;
  font-size: 14px;
  font-weight: bolder;
}

.open-door-subtitle {
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  color: #a0a0a0;
}

.back_home {
  width: 40vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 50px;
}

.open-door-tips {
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-door-tips-icon {
  height: 16px;
  margin-right: 10px;
}

.open-door-tips-text {
  font-size: 10px;
  font-weight: 500;
}
</style>
