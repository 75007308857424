<template>
  <div class="error-page">
    <h1>出错了</h1>
    <p>我们无法处理你的请求。请稍后再试。</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
}

.error-page h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.error-page p {
  margin-bottom: 2em;
}
</style>
