<template>
  <div>
    <van-nav-bar :fixed="true" :placeholder="true" right-text="订单" bink:click-right="viewOrder">
      <template #title>
        <div @touchstart="gtouchstart" @touchmove="gtouchmove" @touchend="gtouchend">
          {{ serialNumber }}
        </div>
      </template>
      <template #right>
        <van-icon name="bill-o" size="24" color="#333" @click="viewOrder" />
      </template>
    </van-nav-bar>
    <div>
      <van-notice-bar left-icon="volume-o" scrollable mode="closeable"
        text="当前您已进入系统监控范围，请注意隐私安全，扫码开门时请确保人在柜前勿让他人拿去商品" />
    </div>
    <div class="sku">
      <div class="sku-item" v-for="sku in skus" v-bind:key="sku">
        <img class="sku-img" :src="sku.pro_img" mode="aspectFit" />
        <div class="sku-name">{{ sku.pro_name }}</div>
        <div class="sku-price">
          <div class="sku-price-icon">¥</div>
          <div class="sku-price-price">
            {{ twoNumber(sku.price) }}
          </div>
          <!-- <div
            class="sku-price-original"
            v-if="sku.is_discount == 1"
          >
            {{ twoNumber(sku.src_price) }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="zw"></div>
  <div class="door">
    <div :class="['open-door', devStatus == 1 ? '' : 'open-door-nouse']" v-if="lockStatus == 0" @click="handleClick">
      立即开门
    </div>
    <div class="open-door-text" v-else-if="lockStatus == 1">
      <div class="open-door-title">正在开锁...</div>
      <div class="open-door-subtitle">"咔"一声后请拉开门</div>
    </div>
    <div class="open-door-text" v-else-if="lockStatus == 2">
      <div class="open-door-title">锁已开，请开门挑选商品</div>
      <div class="open-door-subtitle">取走商品后，关门自动结算</div>
    </div>
    <div class="open-door-tips">
      <img class="open-door-tips-icon" src="../static/wxpayflag.png" />
      <div class="open-door-tips-text">微信支付分 ｜ 550分及以上优享</div>
    </div>
    <div class="open-door-tips">
      <van-checkbox icon-size="14" class="open-door-tips-protocol" v-model="protocol" checked-color="#27b82c"
        @change="agreeProtocol">
      </van-checkbox>
      <div class="open-door-tip-click">点击同意</div>
      <div class="open-door-tip-view" @click="viewprotocol">
        萤点网自动售货机平台用户协议
      </div>
    </div>
  </div>
  <van-icon class="feedback" name="comment-o" color="#fff" size="20" @click="tofeedback" />
  <van-popup :show="show" position="center" @click-overlay="closePopup">
    <van-cell-group inset>
      <van-field v-model="sms" center :clearable="true" :autofocus="true" v-model:model-value="password" type="password"
        label="设备密码" placeholder="请输入设备密码" @update:model-value="pwdChange">
        <template #button>
          <van-button size="small" type="primary" @click="shopen" :disabled="fullscreenLoading">
            确认
          </van-button>
        </template>
      </van-field>
    </van-cell-group>
  </van-popup>
  <Loading v-show="fullscreenLoading" title="Loading..." />
</template>

<script>
import axios from "../axios";
import { mapMutations } from "vuex";
import { showDialog, showConfirmDialog } from "vant";
import wx from "weixin-js-sdk";
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading
  },
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    }
  },
  data() {
    return {
      lockStatus: 0,
      intervalId: null,
      skus: [],
      devStatus: 1,
      timeOutEvent: 0,
      show: false,
      showNum: false,
      password: "",
      protocol: false,
      appid: "",
      openId: "",
      fullscreenLoading: false
    };
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapMutations(["setSerialNumber", "setLockNumber", "setopenId", "setoutOrderNo"]),
    async handleClick() {
      if (!this.protocol) {
        showDialog({
          message: "请同意用户协议",
        });
        return;
      }
      showConfirmDialog({
        title: '提示',
        message: '确认开锁吗?',
      }).then(async() => {
        //confirm
        if (this.devStatus == 1) {
          this.lockStatus = 1;
          try {
            const response = await axios.post("/payscore/order/add", {
              serialNumber: this.serialNumber,
              lockNumber: this.lockNumber,
            });
            if (response.status === 200) {
              debugger
              this.setoutOrderNo(response.data.orderNo);
              let wechatInfo = navigator.userAgent.match(
                /MicroMessenger\/([\d\\.]+)/i
              );
              let wechatVersion = wechatInfo[1];
              if (this.compareVersion(wechatVersion, "7.0.5") >= 0) {
                this.goToWXScore(response.data.queryString);
              } else {
                // 提示用户升级微信客户端版本
                window.href =
                  "https://support.weixin.qq.com/cgi-bin/readtemplate?t=page/common_page__upgrade&text=text005&btn_text=btn_text_0";
              }
            }
          } catch (error) {
            this.lockStatus = 0;
            console.error(error);
          }
        }
      }).catch(() => {

      })

    },
    agreeProtocol(res) {
      console.log(res);
    },
    viewprotocol() {
      this.$router.push("/protocol");
    },
    goToWXScore(queryString) {
      // eslint-disable-next-line no-undef
      let that = this;
      wx.checkJsApi({
        jsApiList: ["openBusinessView"], // 需要检测的JS接口列表
        success: function (res) {
          console.log(res);
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"openBusinessView":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.openBusinessView) {
            // eslint-disable-next-line no-undef
            wx.invoke(
              "openBusinessView",
              {
                businessType: "wxpayScoreUse",
                queryString: queryString, //"mch_id=1230000109&package=xxxxx&timestamp=1530097563&nonce_str=zyx53Nkey8o4bHpxTQvd8m7e92nG5mG2&sign_type=HMAC-SHA256&sign=029B52F67573D7E3BE74904BF9AEA",
              },
              function (res) {
                // 从支付分返回时会执行这个回调函数
                if (parseInt(res.err_code) === 0) {
                  // 返回成功
                  // that.$router.push("/product");
                  that.lockStatus = 2;
                  that.intervalId = setInterval(that.checkStatus, 5000);
                  //跳转 开门结果加载页
                  that.$router.push("/openDoorResult");
                } else {
                  // 返回失败
                  // that.$router.push("/error");
                  that.lockStatus = 0;
                }
              }
            );
          }
        },
      });
    },
    compareVersion(v1, v2) {
      v1 = v1.split(".");
      v2 = v2.split(".");
      const len = Math.max(v1.length, v2.length);

      while (v1.length < len) {
        v1.push("0");
      }
      while (v2.length < len) {
        v2.push("0");
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i]);
        const num2 = parseInt(v2[i]);

        if (num1 > num2) {
          return 1;
        } else if (num1 < num2) {
          return -1;
        }
      }

      return 0;
    },
    async initWxConfig() {
      try {
        // 从后端获取配置信息
        const response = await axios.post("/mp/jsapi/getWxJsapiSignature", {
          url: location.href,
        });
        const config = response.data;
        this.appid = config.appId;
        wx.config({
          debug: false, // 开启调试模式
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.nonceStr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名
          jsApiList: ["openBusinessView"], // 必填，需要使用的JS接口列表
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getProducts() {
      try {
        // 从后端获取配置信息
        const response = await axios.post("/order/products", {
          deviceId: this.serialNumber,
        });
        console.info(response);
        this.skus = response.data.skus;
      } catch (error) {
        console.error(error);
      }
    },
    async checkStatus() {
      let that = this;
      try {
        const response = await axios.post("/order/checklockstatus", {
          serialNumber: this.serialNumber,
          lockNumber: this.lockNumber,
        });
        if (response.status === 200 && response.data.status === 0) {
          that.$router.push("/complete");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async checkDevStatus() {
      let that = this;
      try {
        const response = await axios.post("/order/devStatus", {
          serialize: this.serialNumber,
        });
        let status = response.data;
        console.log(status);
        if (
          status.is_on != "1" ||
          status.lock1_status != "1" ||
          status.door1_status != "0" ||
          status.camera1_status != "1" ||
          status.camera2_status != "1" ||
          status.power_status != "1"
        ) {
          showDialog({
            message: "当前设备不可用！",
          });
          that.devStatus = 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async devInfo() {
      let that = this;
      try {
        const response = await axios.post("/yindian/devInfo", {
          serialize: this.serialNumber,
        });
        let status = response.data;
        console.log(status);
        if (status.status != 1) {
          showDialog({
            message: "当前设备未启用！",
          });
          that.devStatus = 0;
        } else {
          that.checkDevStatus();
        }
      } catch (error) {
        showDialog({
          message: "提交失败",
        });
      }
    },
    twoNumber(val) {
      return (val / 100).toFixed(2);
    },
    gtouchstart() {
      console.log("start");
      let that = this;
      this.timeOutEvent = setTimeout(() => {
        that.longPress();
      }, 5000);
      return false;
    },
    gtouchmove() {
      console.log("move");
      clearTimeout(this.timeOutEvent);
      this.timeOutEvent = 0;
    },
    gtouchend() {
      console.log("end");
      clearTimeout(this.timeOutEvent);
      if (this.timeOutEvent != 0) {
        console.log("click but no touch");
      }
      return false;
    },
    longPress() {
      this.timeOutEvent = 0;
      console.log("long press");
      this.show = true;
    },
    closePopup() {
      this.show = false;
    },
    async shopen() {
      console.log("sh");
      console.log(this.password);
      let that = this;
      if (that.password == "") {
        showDialog({
          message: "设备密码不能为空",
        });
      }
      try {
        const response = await axios.post("/order/goodsUnlock", {
          deviceId: this.serialNumber,
          lockNum: this.lockNumber,
          code: this.password,
        });
        if (response.status === 200) {
          if (response.data.code === 1) {
            that.$router.push("/sh");
          } else {
            showDialog({
              message: "设备密码错误",
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    pwdChange(e) {
      console.log(e);
      console.log(this.password);
    },
    tofeedback() {
      this.$router.push("/feedback");
    },
    viewOrder() {
      this.$router.push("/order");
    },
    getCodeApi() {
      //获取code
      let urlNow = encodeURIComponent(window.location.href);
      // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
      let url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        urlNow +
        "&response_type=code&scope=snsapi_base#wechat_redirect";
      window.location.href = url;
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            window.location.href
          ) || ["", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
  async created() {
    const serialNumber = this.$route.query.serialNumber;
    const lockNumber = this.$route.query.lockNumber;
    if (!serialNumber) {
      this.$router.push("/error");
    } else {
      this.setSerialNumber(serialNumber);
    }
    if (lockNumber) {
      this.setLockNumber(lockNumber);
    }
    await this.initWxConfig();
    let code = this.getUrlKey("code");
    if (code) {
      //获取openid
      let that = this;
      try {
        const response = await axios.post("/mp/jsapi/getOpenid", {
          code: code,
        });
        let status = response.data;
        console.log(status);
        if (status.code != 1) {
          showDialog({
            message: "用户信息获取失败",
          });
        } else {
          this.fullscreenLoading = true;
          that.openId = status.openId;
          this.setopenId(status.openId);
          await this.getProducts();
          //await this.devInfo();
          //await this.checkDevStatus();
          this.fullscreenLoading = false;
        }
      } catch (error) {
        showDialog({
          message: "信息获取失败",
        });
      }
    } else {
      this.getCodeApi();
    }
    //this.devInfo();
    // this.checkDevStatus();
  },
};
</script>

<style scoped>
/* .head {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-text {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none;
}
.top {
  width: 100%;
  height: 30px;
} */
.sku {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 5px;
  box-sizing: border-box;
  background-color: #f8f8f8;
}

.sku-item {
  width: calc((100vw - 10px) / 3 - 10px);
  margin: 5px;
  background-color: #fff;
}

.sku-img {
  width: calc((100vw - 10px) / 3 - 10px);
  height: calc((100vw - 10px) / 3 - 10px);
}

.sku-name {
  height: 40px;
  line-height: 20px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 14px;
  padding: 2px;
}

.sku-price {
  padding: 0 5px;
  color: red;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.sku-price-icon {
  font-size: 14px;
  margin-right: 2px;
}

.sku-price-price {
  font-size: 16px;
  margin-right: 5px;
}

.sku-price-original {
  font-size: 12px;
  color: #afafaf;
  text-decoration: line-through;
}

.zw {
  width: 100vw;
  height: 100px;
}

.door {
  border-top: solid #f7f1f1 1px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 999;
  align-items: center;
}

.open-door {
  width: 80vw;
  height: 36px;
  line-height: 36px;
  background-color: #27b82c;
  color: #fff;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: 10px;
}

.open-door-nouse {
  background-color: #d6e1d6;
}

.open-door-text {
  margin-top: 10px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-door-title {
  height: 25px;
  font-size: 14px;
  font-weight: bolder;
}

.open-door-subtitle {
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  color: #a0a0a0;
}

.open-door-tips {
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.open-door-tips-icon {
  height: 16px;
  margin-right: 10px;
}

.open-door-tips-text {
  font-size: 10px;
  font-weight: 500;
}

.open-door-tips-protocol {
  font-size: 10px;
  font-weight: 500;
  margin: 0 2px;
}

.open-door-tip-view {
  font-size: 10px;
  font-weight: 500;
  color: #1c691f;
}

.open-door-tip-click {
  font-size: 10px;
  font-weight: 500;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.open-door-button {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 14px;
  color: #fff;
  background-color: #4caf50;
}

.feedback {
  position: fixed;
  right: 10px;
  bottom: 120px;
  width: 40px;
  height: 40px;
  background-color: #27b82c;
  line-height: 40px;
  border-radius: 50%;
}
</style>
