// ProductView.vue
<template>
  <div>
    <p>商品展示页</p>
  </div>
</template>

<script>
import axios from "../axios";

export default {
  computed: {
    serialNumber() {
      return this.$store.state.serialNumber;
    },
    lockNumber() {
      return this.$store.state.lockNumber;
    },
  },
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    this.intervalId = setInterval(this.checkStatus, 5000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    async checkStatus() {
      let that = this;
      try {
        const response = await axios.post("/order/checklockstatus", {
          serialNumber: this.serialNumber,
          lockNumber: this.lockNumber,
        });
        if (response.status === 200 && response.data.status === 0) {
          that.$router.push("/complete");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
