import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import Product from "../views/ProductView.vue";
import Complete from "../views/CompleteView.vue";
import Sh from "../views/ShView.vue";
import Error from "../views/ErrorView.vue";
import ErrorWX from "../views/ErrorWXView.vue";
import Feedback from "../views/FeedbackView.vue";
import Protocol from "../views/ProtocolView.vue";
import Order from "../views/OrderView.vue";
import OpenDoorResult from "../views/OpenDoorResultView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/complete",
    name: "Complete",
    component: Complete,
  },
  {
    path: "/sh",
    name: "Sh",
    component: Sh,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
  {
    path: "/errorwx",
    name: "ErrorWX",
    component: ErrorWX,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: Protocol,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
  },
  {
    path: "/openDoorResult",
    name: "OpenDoorResult",
    component: OpenDoorResult,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
